@tailwind base;
@import "air-datepicker/air-datepicker.css";
@import "react-image-crop/dist/ReactCrop.css";
@import "react-mde/lib/styles/css/react-mde-all.css";
@import "react-toastify/dist/ReactToastify.css";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "react-circular-progressbar/dist/styles.css";
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Quicksand:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Special+Elite&display=swap");

.special-elite-regular {
    font-family: "Special Elite", system-ui;
    font-weight: 400;
    font-style: normal;
}

@keyframes borealisBar {
    0% {
        left: 0%;
    }

    100% {
        left: calc(100% - 5rem);
    }
}

body {
    @apply m-0 font-nunito text-thunder;
}

h1,
h2,
h3,
h4,
h5 {
    @apply font-roboto;
}

a {
    @apply text-christine;
}

.drag-over {
    @apply bg-mercury;
}

.drag-over.bounce > div {
    @apply animate-directory-bounce;
}

.max-w-very-xs {
    max-width: 10rem !important;
}

.admin tbody tr:nth-child(odd) {
    @apply bg-geyser bg-opacity-50;
}

.welcome h1 {
    @apply text-2xl font-bold;
}

.welcome h2 {
    @apply text-xl font-bold;
}

.welcome p {
    @apply my-4;
}

.checklist-item ul {
    @apply ml-8 list-disc;
}

@layer components {
    .mde-tabs button {
        @apply px-2;
    }

    .btn {
        @apply rounded px-8 py-2 text-center font-bold;
        @apply text-white shadow-atomic-tangerine;
        transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
            box-shadow 0.15s ease-in-out 0s;

        @apply bg-atomic-tangerine hover:bg-chardonnay;
    }

    .btn:disabled,
    .btn.disabled {
        @apply cursor-not-allowed bg-regent-gray text-geyser shadow-none;
    }

    .btn:active:not(:disabled),
    .btn.active:not(:disabled) {
        @apply bg-atomic-tangerine shadow-inner;
    }

    .btn.white {
        @apply bg-white text-inner-space shadow-md;
        @apply hover:bg-white hover:text-regent-gray;
    }

    .btn.white:disabled,
    .btn.white.disabled {
        @apply cursor-not-allowed bg-geyser text-regent-gray shadow-none;
    }

    .btn.white:active:not(:disabled),
    .btn.white.active:not(:disabled) {
        @apply bg-white text-inner-space shadow-inner;
    }

    .btn.green,
    .btn.green:hover,
    .btn.green.hover,
    .btn.green:active:not(:disabled),
    .btn.green.active:not(:disabled) {
        @apply bg-sushi;
    }

    .btn.gray,
    .btn.gray:hover,
    .btn.gray.hover,
    .btn.gray:active:not(:disabled),
    .btn.gray.active:not(:disabled) {
        @apply bg-regent-gray;
    }

    .btn.gray:active:not(:disabled),
    .btn.gray.active:not(:disabled),
    .btn.green:active:not(:disabled),
    .btn.green.active:not(:disabled) {
        @apply shadow-none;
    }

    .btn.premium {
        @apply bg-christine;
    }

    .btn:focus {
        @apply outline-none;
    }

    [data-sm]::after,
    [data-md]::after,
    [data-lg]::after,
    [data-xl]::after,
    [data-2xl]::after {
        content: "";
    }

    @screen sm {
        [data-sm]::after {
            content: attr(data-sm);
        }

        [data-sm] > span {
            display: none;
        }
    }

    @screen md {
        [data-md]::after {
            content: attr(data-md);
        }

        [data-md] > span {
            display: none;
        }
    }

    @screen lg {
        [data-lg]::after {
            content: attr(data-lg);
        }

        [data-lg] > span {
            display: none;
        }
    }

    @screen xl {
        [data-xl]::after {
            content: attr(data-xl);
        }

        [data-xl] > span {
            display: none;
        }
    }

    @screen 2xl {
        [data-2xl]::after {
            content: attr(data-2xl);
        }

        [data-2xl] > span {
            display: none;
        }
    }

    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }

    .linear-background {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        background-size: 1000px 104px;
        height: 338px;
        position: relative;
        overflow: hidden;
    }
}

:focus {
    @apply outline-none;
}

[type="search"] {
    @apply m-0 rounded-none border p-2;
}

[type="search"]:focus {
    @apply outline-none;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
    @apply m-0 rounded-none border border-geyser text-regent-gray;
}

[type="text"]:not(.p-0),
[type="email"]:not(.p-0),
[type="url"]:not(.p-0),
[type="password"]:not(.p-0),
[type="number"]:not(.p-0),
[type="date"]:not(.p-0),
[type="datetime-local"]:not(.p-0),
[type="month"]:not(.p-0),
[type="tel"]:not(.p-0),
[type="time"]:not(.p-0),
[type="week"]:not(.p-0),
textarea:not(.p-0) {
    @apply p-2;
}

[type="text"]:is([role="combobox"]) {
    @apply p-0 !important;
}

:is(
        [type="checkbox"],
        [type="radio"],
        [type="text"],
        [type="email"],
        [type="url"],
        [type="password"],
        [type="number"],
        [type="date"],
        [type="datetime-local"],
        [type="month"],
        [type="tel"],
        [type="time"],
        [type="week"],
        [multiple],
        textarea,
        select
    ):focus {
    @apply shadow-none outline-none;
}

[type="checkbox"],
[type="radio"] {
    @apply inline-block shrink-0 select-none appearance-none border border-christine bg-white fill-current p-0 align-middle text-christine;
    @apply h-4 w-4;
    color-adjust: "exact";
    background-origin: "border-box";
}

[type="checkbox"] {
    @apply rounded-none;
    @apply bg-white fill-current;
}

[type="radio"] {
    @apply rounded-100;
    @apply bg-white;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
    @apply border-transparent bg-center bg-no-repeat;
    background-size: 100% 100%;
}

[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='%23FDA86A' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E%0A");
}

[type="checkbox"]:disabled {
    @apply bg-regent-gray;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='%232F393E' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E%0A");
}

[type="radio"]:checked {
    @apply bg-current;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}

.arrow-rename:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.578' height='47.254' viewBox='0 0 25.578 47.254'%3E%3Cpath d='M7.939 9.639A32.128 32.128 0 0120.027.279l.622-.283 4.929 10.838-.622.283a20.183 20.183 0 00-7.617 5.827 14.594 14.594 0 00-2.747 5.631 10.89 10.89 0 00-.134 4.584l3.7-3 3.688 23.093-21.844-8.35 4.744-3.852a21.726 21.726 0 01-1.741-15.2A26.409 26.409 0 017.939 9.639z' fill='%23f15f12'/%3E%3C/svg%3E%0A");
    @apply absolute;
    width: 26px;
    height: 47px;
    bottom: -35px;
    left: -30px;
}

.password-icon {
    right: 10px;
    text-indent: 32px;
    top: 10px;
    @apply absolute left-auto cursor-pointer text-geyser;
}

.pulse-button {
    @apply relative cursor-pointer rounded-50 border-none bg-cover bg-no-repeat;
    width: 110px;
    height: 110px;
    box-shadow: 0 0 0 0 rgba(254, 230, 213, 0.7);
    background-color: rgb(254, 230, 213);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.no-pulse {
    animation: none;
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 30px rgba(254, 230, 213, 0);
    }
}

.menu-switch {
    top: 50%;
    right: -0.5rem;
}

:root {
    --toastify-color-info: #76cfea;
    --toastify-color-success: #95c445;
    --toastify-color-warning: #fda86a;
    --toastify-color-error: #ff5d5d;
    --base-font-size: 2rem;
}

.ReactModal__Overlay {
    @apply opacity-0 transition-opacity duration-200 ease-in-out;
}

.ReactModal__Overlay--after-open {
    @apply opacity-100;
}

.ReactModal__Overlay--before-close {
    @apply opacity-0;
}

/* loading */
.breeding-rhombus-spinner {
    height: 65px;
    width: 65px;
    @apply relative;
    transform: rotate(45deg);
}

.breeding-rhombus-spinner,
.breeding-rhombus-spinner * {
    @apply box-border;
}

.breeding-rhombus-spinner .rhombus {
    height: calc(65px / 7.5);
    width: calc(65px / 7.5);
    animation-duration: 2000ms;
    top: calc(65px / 2.3077);
    left: calc(65px / 2.3077);
    @apply absolute bg-christine;
    animation-iteration-count: infinite;
}

.breeding-rhombus-spinner .rhombus:nth-child(2n + 0) {
    @apply mr-0;
}

.breeding-rhombus-spinner .rhombus.child-1 {
    animation-name: breeding-rhombus-spinner-animation-child-1;
    animation-delay: calc(100ms * 1);
}

.breeding-rhombus-spinner .rhombus.child-2 {
    animation-name: breeding-rhombus-spinner-animation-child-2;
    animation-delay: calc(100ms * 2);
}

.breeding-rhombus-spinner .rhombus.child-3 {
    animation-name: breeding-rhombus-spinner-animation-child-3;
    animation-delay: calc(100ms * 3);
}

.breeding-rhombus-spinner .rhombus.child-4 {
    animation-name: breeding-rhombus-spinner-animation-child-4;
    animation-delay: calc(100ms * 4);
}

.breeding-rhombus-spinner .rhombus.child-5 {
    animation-name: breeding-rhombus-spinner-animation-child-5;
    animation-delay: calc(100ms * 5);
}

.breeding-rhombus-spinner .rhombus.child-6 {
    animation-name: breeding-rhombus-spinner-animation-child-6;
    animation-delay: calc(100ms * 6);
}

.breeding-rhombus-spinner .rhombus.child-7 {
    animation-name: breeding-rhombus-spinner-animation-child-7;
    animation-delay: calc(100ms * 7);
}

.breeding-rhombus-spinner .rhombus.child-8 {
    animation-name: breeding-rhombus-spinner-animation-child-8;
    animation-delay: calc(100ms * 8);
}

.breeding-rhombus-spinner .rhombus.big {
    height: calc(65px / 3);
    width: calc(65px / 3);
    animation-duration: 2000ms;
    top: calc(65px / 3);
    left: calc(65px / 3);
    @apply bg-christine;
    animation: breeding-rhombus-spinner-animation-child-big 2s infinite;
    animation-delay: 0.5s;
}

@keyframes breeding-rhombus-spinner-animation-child-1 {
    50% {
        transform: translate(-325%, -325%);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-2 {
    50% {
        transform: translate(0, -325%);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-3 {
    50% {
        transform: translate(325%, -325%);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-4 {
    50% {
        transform: translate(325%, 0);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-5 {
    50% {
        transform: translate(325%, 325%);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-6 {
    50% {
        transform: translate(0, 325%);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-7 {
    50% {
        transform: translate(-325%, 325%);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-8 {
    50% {
        transform: translate(-325%, 0);
    }
}

@keyframes breeding-rhombus-spinner-animation-child-big {
    50% {
        transform: scale(0.5);
    }
}

.cms-page h1 {
    @apply my-4 text-2xl font-bold;
}

.cms-page h2 {
    @apply my-4 text-xl font-bold;
}

.cms-page h3 {
    @apply my-2 text-lg font-bold;
}

.cms-page h4,
.cms-page h5,
.cms-page h6 {
    @apply my-2 font-bold;
}

.cms-page p {
    @apply mb-2;
}

.widget-desc {
    @apply ml-8;
}

.widget-desc ul {
    @apply list-none;
}

.widget-desc ul li {
    @apply relative mb-8;
}

.widget-desc ul li:before {
    content: " ";
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.578 19'%3E%3Cpath d='M0 17.463V1.5L11.413 0v1.592h5.925a1.232 1.232 0 011.24 1.22v13.331a1.232 1.232 0 01-1.24 1.22h-5.925V19zm11.413-.8a.512.512 0 00.077.008h5.848a.528.528 0 00.531-.523V2.808a.528.528 0 00-.531-.523H11.49a.574.574 0 00-.077.008v4.081h5.347a.231.231 0 110 .462h-5.347v1.385h5.255a.231.231 0 110 .461h-5.255v1.373h5.255a.231.231 0 110 .461h-5.255v1.417h5.255a.231.231 0 110 .462h-5.255z' fill='%23b5c0cc'/%3E%3C/svg%3E");
    @apply absolute -left-8 h-7 w-7 bg-no-repeat;
}

.widget-desc ul li ul {
    @apply ml-6 mt-4;
}

.widget-desc ul li ul li {
    @apply relative mb-2;
}

.widget-desc ul li ul li:before {
    content: " ";
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26.651' height='22.849' viewBox='0 0 26.651 22.849'%3E%3Cpath d='M24.751 3.273A1.312 1.312 0 0023.394 1.9H11.833a.44.44 0 01-.363-.143L10.131.422 10.119.41A1.293 1.293 0 009.09 0H3.372a1.416 1.416 0 00-1.47 1.369v4.344h22.849zM1.902 7.378h-.494c-.762 0-1.505.3-1.4 1.446s1.4 12.535 1.4 12.535c.161 1.059.7 1.488 1.488 1.488H23.87c.756 0 1.25-.464 1.369-1.488 0 0 1.321-11 1.4-12.228s-.53-1.755-1.4-1.755H1.902z' fill='%23B5C0CC'/%3E%3Cpath d='M13.601 15.557a2 2 0 10-2-2 2 2 0 002 2zm1.78.445h-.767a2.42 2.42 0 01-2.027 0h-.766a1.78 1.78 0 00-1.78 1.784v.222a.668.668 0 00.667.667h5.785a.668.668 0 00.667-.667v-.222a1.78 1.78 0 00-1.78-1.78z' fill='%23fff'/%3E%3C/svg%3E%0A");
    @apply absolute -left-8 h-7 w-7 bg-no-repeat;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
    background-color: #0c7eaf;
    color: #fff;
}

.suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}

.name {
    margin-left: 68px;
    line-height: 45px;
}

.highlight {
    color: #ee0000;
    font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
    color: #120000;
}
